<template>
  <div>
    <v-row>
      <v-col cols="8" xs="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
      <v-col cols="4" xs="12" align="right">
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" class="col-xs-12">
        <h2 style="font-size: 25px !important;">Liste des tarifs</h2>
      </v-col>
      <v-col xs="12" class="col-xs-12">
        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2 float-right"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon small>
                mdi-plus
              </v-icon>
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-form @submit.prevent="save">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                      <p class="text--primary mt-n3 mb-2">
                        Option de livraison
                      </p>
                      <v-radio-group
                          v-model="editedItem['tree_item\\deliverymode.id']"
                          row
                          class="mt-0"
                          hide-details
                      >
                        <v-radio
                            v-for="tt in deliverymodes"
                            :key="tt.name"
                            :label="tt.name"
                            :value="tt.id"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                      <p class="text--primary mt-n3 mb-2">
                        Type de tariffaire
                      </p>
                      <v-radio-group
                          v-model="editedItem.tarifftype"
                          row
                          class="mt-0"
                          hide-details
                      >
                        <v-radio
                            v-for="tt in tariff_types"
                            :key="tt.name"
                            :label="tt.name"
                            :value="tt.key"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                      <p class="text--primary mt-n3">
                        Couverture du tariff
                      </p>
                      <v-radio-group
                          v-model="editedItem['tree_item\\coverage.id']"
                          row
                          class="mt-0"
                          hide-details
                      >
                        <v-radio
                            v-for="tt in coverages"
                            :key="tt.name"
                            :label="tt.name"
                            :value="tt.id"
                            @click="setCoverage(tt)"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                      <v-select
                          v-if="coverage.name == 'interurban' || coverage.name == 'urban'"
                          :items="countries"
                          @change="getCities"
                          item-value="iso"
                          item-text="name"
                          outlined
                          dense
                          label="Pays deservis" v-model="editedItem['countryiso']">
                      </v-select>
                      <v-select
                          v-if="coverage.name == 'international'"
                          :items="countries"
                          item-value="iso"
                          item-text="name"
                          outlined
                          dense
                          label="Pays de Départ"
                          v-model="editedItem['countryiso']">
                      </v-select>

                      <v-radio-group v-if="coverage.name == 'interurban'"
                                     v-model="departure_from"
                                     row
                                     class="mt-0"
                                     hide-details
                      >
                        <v-radio
                            label="Départ d'une ville spécifique : "
                            class="mt-2"
                            value="one"
                        >
                        </v-radio>
                        <v-radio
                            label="Départ de toutes les ville : "
                            class="mt-2"
                            value="all"
                        >
                        </v-radio>
                      </v-radio-group>

                      <v-autocomplete
                          v-if="coverage.name == 'interurban' && departure_from === 'one'"
                          v-model="departures"
                          :items="cities"
                          :search-input.sync="searchdeparture"
                          :loading="isDestinationLoading"
                          outlined
                          dense
                          item-value="name"
                          item-text="name"
                          chips
                          small-chips
                          label="Selectionner un ou plusieurs ville de départ"
                          multiple
                      ></v-autocomplete>

                    </v-col>

                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                      <v-radio-group v-if="coverage.name == 'interurban'"
                                     v-model="delivery_to"
                                     row
                                     class="mt-0"
                                     hide-details
                      >
                        <v-radio
                            label="Livraison vers une ville spécifique : "
                            value="one"
                        >
                        </v-radio>
                        <v-radio
                            label="Livraison vers toutes les villes : "
                            value="all"
                        >
                        </v-radio>
                      </v-radio-group>

                      <v-select
                          v-if="coverage.name == 'international'"
                          :items="countries"
                          item-value="iso"
                          item-text="name"
                          outlined
                          dense
                          label="Pays de destination" v-model="editedItem['destination']">
                      </v-select>
                      <v-autocomplete
                          v-else-if="delivery_to === 'one'"
                          v-model="destinations"
                          :items="cities"
                          :search-input.sync="searchdeparture"
                          outlined
                          :loading="isDestinationLoading"
                          dense
                          item-value="name"
                          item-text="name"
                          chips
                          small-chips
                          label="Selectionner un ou plusieurs ville de destination"
                          multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="editedItem.tarifftype === 'w' || editedItem.tarifftype === 'all'"
                    >
                      <v-select
                          :items="intervalweights"
                          item-value="id"
                          item-text="label"
                          outlined
                          dense
                          @change="setminmaxweight"
                          label="Interval de piods" v-model="editedItem['weight_interval.id']">
                      </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="editedItem.tarifftype === 'p' || editedItem.tarifftype === 'all'"
                    >
                      <v-select
                          :items="intervalprices"
                          item-value="id"
                          item-text="label"
                          outlined
                          dense
                          label="Interval de price" v-model="editedItem['price_interval.id']">
                      </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          v-model="editedItem.min"
                          :rules="minRules"
                          label="Valeur min"
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          v-model="editedItem.max"
                          :rules="maxRules"
                          label="Valeur max"
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                      <v-text-field
                          v-model="editedItem.cost"
                          :rules="costRules"
                          label="Coût"
                          outlined
                          dense
                      ></v-text-field>
                      <v-radio-group
                          v-model="editedItem.costtype"
                          row
                      >
                        <v-radio key="cash"
                                 label="Prix net"
                                 value="cash"
                        ></v-radio>
                        <v-radio key="percentage"
                                 label="Prix en pourcentage"
                                 value="percentage"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-expansion-panels>

                      <v-expansion-panel
                          key="affiliate"
                      >
                        <v-expansion-panel-header>
                          Coût supplémentaire
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list-item-group
                              active-class=""
                          >
                            <v-list-item v-for="item in supplements" :key="item.id">
                              <template v-slot:default="{  }">
                                <v-list-item-action>
                                  <v-checkbox
                                      v-model="editedItem['supplementprice.id']" :value="item.id"></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content>
                                  <v-list-item-title>{{ item.price }}XAF / {{ item.weight }}kg</v-list-item-title>
                                  <v-list-item-subtitle>{{ item.label }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="error"
                    text
                    @click="close"
                >
                  Fermer
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    dark
                    type="submit"
                >
                  <v-icon
                      left
                      dark
                  >
                    mdi-content-save-check
                  </v-icon>
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card-title>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDelete">Annuler</v-btn>
            <v-btn color="success" text @click="deleteItemConfirm">Ok</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-row>
          <v-expansion-panels>

            <v-expansion-panel
                key="filter"
            >
              <v-expansion-panel-header>
                Filtre
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card elevation="0">
                  <v-card-text>
                    <v-row>
                      <v-col lg="12">
                        <v-select
                            :items="countries"
                            item-value="iso"
                            item-text="name"
                            outlined
                            dense
                            label="Pays deservis" v-model="search['country.iso:eq']">
                        </v-select>
                      </v-col>
                      <v-col v-if="false" lg="4">
                        Couverture du tariff
                        <v-radio-group dense row>
                          <v-checkbox dense v-for="tt in coverages" :key="tt.name"
                                      :label="tt.name"
                                      :value="tt.name"
                                      v-model="search['tree_item.name:in']"
                          ></v-checkbox>
                        </v-radio-group>
                      </v-col>
                      <v-col v-if="false" lg="4">
                        Mode de livraison
                        <v-radio-group dense row>
                          <v-checkbox dense v-for="tt in deliverymodes" :key="tt.name"
                                      :label="tt.name"
                                      :value="tt.name"
                                      v-model="search.deliverymode"
                          ></v-checkbox>
                        </v-radio-group>
                      </v-col>
                      <v-col v-if="false" lg="4">
                        Type de tariffaire
                        <v-radio-group dense row>
                          <v-checkbox dense v-for="tt in tariff_types" :key="tt.name"
                                      :label="tt.name"
                                      :value="tt.name"
                                      v-model="search.tariff_type"
                          ></v-checkbox>
                        </v-radio-group>
                      </v-col>
                      <v-col lg="6">
                        <v-text-field
                            v-model="search['departure:opt']" label="Ville de depart"></v-text-field>
                      </v-col>
                      <v-col lg="6">
                        <v-text-field
                            v-model="search['destination:opt']" label="Ville de destination"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2" @click="filterTariff()">filtrer les tariffs
                    </v-btn>
                    <v-btn
                        color="default"
                        dark
                        class="mb-2" @click="fetchItems()">reset filter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </v-row>

      <v-spacer></v-spacer>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :server-items-length="tariff_ll.nb_element"
        hide-default-footer
        sort-by="calories"
        class="table-rounded interval-tariff-table"
    >

      <template v-slot:body.prepend="">

      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            right
            color="warning"
            dark
            class="mx-1 text-right"
            @click="editItem(item)"
        >
          <v-icon
              small
          >
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
            right
            color="error"
            dark
            class="mx-1 text-right"
            @click="deleteItem(item)"

        >
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="tariff_ll.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="tariff_ll.current_page"
              total-visible="6"
              :length="parseInt(tariff_ll.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>

import {Drequest} from "../../plugins/Drequest";
import breadcrumbs from "../../components/ui/breadcrumbs";
const moonLanding = new Date();

export default {
  name: "tariff",
  components: {
    breadcrumbs
  },
  data: () => ({
    dataBreadcrumbs: [],
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    loading: false,
    isDestinationLoading: false,
    dialog: false,
    dialogDelete: false,
    destination: "",
    destinations: [],
    departure_from: "one",
    delivery_to: "one",
    optionsLocal: {},
    resetForm: {},
    headers: [
      {text: 'Pays', value: 'country.iso'},
      {text: 'Départ', value: 'departure'},
      {text: 'Destination', value: 'destination'},
      {text: 'Type Tarif', value: 'tarifftype'},
      {text: 'Valeur min', value: 'min'},
      {text: 'Valeur max', value: 'max'},
      {
        text: 'Coût',
        align: 'start',
        sortable: false,
        value: 'cost',
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    search: {
      status: 1,
      'tarifftype:in': [],
      'deliverymode:in': [],
      'tree_item.name:in': [],
      'departure:opt': "",
      'destination:opt': "",
      'country.iso:eq': "",
    },
    items: [],
    departures: [],
    searchdeparture: '',
    deliverymode: [],
    cities: [],
    tariff_ll: {pagination: 1},
    coverage: {},
    tariff_type: {},
    tariff_types: [
      {name: "Poids", key: "w"},
      {name: "Prix", key: "p"},
      {name: "Km", key: "k"},
      //{name: "Tout", key: "all"},
    ],
    editedIndex: -1,
    editedItem: {costtype: "cash"},
    defaultItem: {},


    costRules: [
      v => !!v || 'Le coût du tariffaire est important',
      //v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    minRules: [
      v => !!v || 'La valeur du tariffaire est important',
      //v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    maxRules: [
      v => !!v || 'La valeur du tariffaire est important',
      //v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],

  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau tarif' : 'Modifier le tarif'
    },
    supplements() {
      return this.$store.state.supplements
    },
    intervalprices() {
      return this.$store.state.intervalprices
    },
    intervalweights() {
      return this.$store.state.intervalweights
    },
    // items() {
    //   return this.$store.state.items
    // },
    coverages() {
      return this.$store.state.coverages
    },
    countries() {
      return this.$store.state.countries
    },
    deliverymodes() {
      return this.$store.state.deliverymodes
    },
    statues() {
      return this.$store.state.statues
    },
    // tariff_ll(){
    //   return this.$store.state.tariff_ll
    // }
  },

  watch: {

    searchdeparture(val) {
      console.log(val)
      this.getCitiesByName(val)
    },
    dialog(val) {
      val || this.close()
    },
    departure_from(val) {
      if (val === "all")
        this.departure = val
    },
    delivery_to(val) {
      if (val === "all")
        this.destination = val
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Gestion des Tarifs',
        disabled: true,
        href: '/',
      }
    ];
    this.initialize();
    this.fetchItems();
  },

  methods: {
    setCoverage(tt) {
      this.coverage = tt
      this.departure_from = "one"
      this.delivery_to = "one"
    },
    filterTariff() {
      this.loading = true;
      Drequest.api("lazyloading.tarrif?dfilters=on&transporter.id:eq=" + this.$store.state.transporter.id)
          .param(this.search)
          .get((response) => {
            console.log(response);
            this.loading = false;
            this.tariff_ll = response;
            this.items = this.tariff_ll.listEntity;
          })
    },
    fetchItems() {
      this.search = {
        'tarifftype:in': [],
        'deliverymode:in': [],
        'tree_item.name:in': [],
        'departure:opt': "",
        'destination:opt': "",
        'country.iso:eq': "",
      }
      this.loading = true;
      console.log(this.tariff_ll)
      Drequest.api("lazyloading.tarrif?dfilters=on&transporter.id:eq=" + this.$store.state.transporter.id)
          .param({
            next: this.tariff_ll.current_page,
            per_page: this.tariff_ll.per_page,
          })
          .get((response) => {
            console.log(response);
            this.tariff_ll = response;
            this.items = this.tariff_ll.listEntity;

            this.loading = false;
          })
    },
    initialize() {
      this.loading = true;
      this.$store.dispatch('initTarif').then((res) => {
        console.log(res)
        this.loading = false;
      })
    },
    getCities() {
      this.isDestinationLoading = true
      Drequest.api("city.fetch?country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = response.cities;
                this.isDestinationLoading = false
              }/*,
              {
                "async": true,
                "crossDomain": true,
                "headers": {
                  "x-rapidapi-host": "wft-geo-db.p.rapidapi.com",
                  "x-rapidapi-key": "7d603cb497msh94f0a53d1ba8bcfp162e4fjsnefccafef0bd5"
                }
              }*/
          )
    },
    getCitiesByName(name) {
      if (!name)
        return 1;

      if (name.length < 2 || this.isDestinationLoading)
        return 1;

      this.isDestinationLoading = true
      Drequest.api("city.fetch?name=" + name + "&country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = [...this.cities, ...response.cities];
                this.isDestinationLoading = false
              }
          )

    },
    copyItem(item) {
      this.editedItem = {
        min: parseFloat(item.max) + 1,
        max: "",
        cost: "",
        tarifftype: item.tarifftype,
        "country.id": item.country.id,
        "tree_item\\deliverymode.id": item.deliverymode.id,
        "tree_item\\coverage.id": item.coverage.id,
        "weight_interval.id": item.weight_interval.id,
        "price_interval.id": item.price_interval.id,
        "costtype": item.costtype,
        "destination": item.destination,
        "departure": item.departure,
      }
      this.departures = item.departures;
      this.destinations = item.destinations;
      this.coverage = item.coverage;

      this.dialog = true
    },
    setminmax(item) {
      console.log(item);
      for (var el of this.intervalprices) {
        if (el.id == item) {
          this.editedItem.min = el.price_min;
          this.editedItem.max = el.price_max;
          break;
        }
      }
    },
    setminmaxweight(item) {
      console.log(item);
      for (var el of this.intervalweights) {
        if (el.id == item) {
          this.editedItem.min = el.min;
          this.editedItem.max = el.max;
          break;
        }
      }
    },
    editItem(item) {
      console.log(item)
      this.editedIndex = this.items.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      this.editedItem = {
        id: item.id,
        min: item.min,
        max: item.max,
        cost: item.cost,
        tarifftype: item.tarifftype,
        "tree_item\\deliverymode.id": item.deliverymode.id,
        "country.id": item.country.id,
        "supplementprice.id": item.supplementprice.id,
        "tree_item\\coverage.id": item.coverage.id,
        "weight_interval.id": item.weight_interval.id,
        "price_interval.id": item.price_interval.id,
        "costtype": item.costtype,
        "destination": item.destination,
        "departure": item.departure ?? item.destination,
      }
      this.coverage = item.coverage;
      if (item.weight_interval.id)
        this.tariff_type = this.tariff_types[0];
      else if (item.price_interval.id)
        this.tariff_type = this.tariff_types[1];

      this.departures = item.departures;
      this.destinations = item.destinations;
      this.dialog = true
    },
    deleteItem(item) {
      this.editedItem = item
      this.editedIndex = this.items.indexOf(item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.loading = true
      Drequest.api("delete.tarrif?id=" + this.editedItem.id)
          .get((response) => {
            console.log(response)
            this.editedItem = Object.assign({}, this.defaultItem)
            this.items.splice(this.editedIndex, 1)
            this.closeDelete()
            this.loading = false
          })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.loading = false;
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.tariff_type = this.tariff_types[0]
        this.editedItem = {
          tarifftype: "w",
          "country.id": this.countries[0].id,
          "costtype": this.costtype,
          "tree_item\\coverage.id": this.coverages[0].id,
          "tree_item\\deliverymode.id": this.deliverymodes[0].id,
        }
        this.editedIndex = -1
      })
    },
    save() {
      if (!this.editedItem["tree_item\\deliverymode.id"]) {
        alert("vous devez choisir une option de livraison!")
        return;
      }
      if (!this.editedItem["tree_item\\coverage.id"]) {
        alert("vous devez choisir une couverture!")
        return;
      }
      this.editedItem.departure = this.departures.join(";")
      this.editedItem.destination = this.destinations.join(";")
      if (this.coverage.name === "urban")
        this.editedItem.departure = this.editedItem.destination

      if (this.coverage.name === "international"){
        this.editedItem.departure = "all"
        this.editedItem.destination = "all"
      }

      if (this.departure_from === "all")
        this.editedItem.departure = "all"

      if (this.delivery_to === "all")
        this.editedItem.destination = "all"
      this.loading = true;
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        const promise = this.$store.dispatch('updateTarif', this.editedItem)
        promise.then((res) => {
          console.log(res, this.editedIndex)

          this.fetchItems();
          this.initialize()
          this.close()
        })
            .catch(err => {
              console.log(err)
            })
      } else {
        const promise = this.$store.dispatch('saveTarif', {tarrif: this.editedItem})
        promise.then((res) => {
          console.log(res)
          this.items.push(res.tarrif)
          this.initialize()
          this.close()
        })
            .catch(err => {
              console.log(err)
            })
      }
    },
  },
}
</script>

<style scoped>

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: auto;
  font-weight: 600;
  padding: 10px;
  color: white;
}

.interval-tariff-table {
  margin: 0 !important;
  margin-top: 30px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 8px;
  display: none;
}

.v-card__title {
  display: revert !important;
}
</style>